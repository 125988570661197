nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #1e1e1e;
    height: 50px;
    padding: 15px;
    width: 100vw;
    box-shadow: 0 20px 20px rgb(0 0 0 / 0.5);
    z-index: 999;
}

nav a {
    margin: 0 2vw;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 0.8rem;
    text-decoration: none;
}

.socials {
    margin: 0 1rem;


}



nav a:hover{
    cursor: pointer;
    font-weight: bold;
    transition: 0.5s;
}

#nav-button{
    border: 1px solid white;
    color: white;
    background-color: unset;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    text-transform: uppercase;
    position: absolute;
    right: 20px;
    top: 10px;
    }


button:hover{
    cursor: pointer;
}