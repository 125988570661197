*{
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Roboto', sans-serif;
}

body{
  background-color: black;

}

#home > img{
  width: 100%;
  min-width: 750px ;
} 

